<template>
  <div class="page page-update-email">
    <AccountHeader>
      <template #breadcrumb>
        <Breadcrumb :items="[{label: $t('Account'), click: () => $router.push({name: 'Account'})}, {label: $t('Update your email')}]"/>
      </template>
    </AccountHeader>
    <div class="p-3" v-if="step === 1">
      <h1>{{$t("Update your email")}}</h1>
      <div class="description mb-3">
        {{$t("Your recovery email is used to contact you in the event that we detect unusual activity in your account or you are accidentally blocked.")}}
      </div>
      <b-button variant="secondary" class="text-uppercase font-weight-bold" block @click="verify()" :disabled="loading">{{$t("Update a email")}}</b-button>
    </div>
    <div class="p-3" v-else>
      <h1>{{$t("Set your new email")}}</h1>
      <div class="description mb-3">
        {{$t("This email can be used to login and retrieve the password. We will contact you via this email in case contact is needed.")}}
      </div>
      <b-alert :show="requireCode" v-html="$t('Secure code has been sent to your email')" variant="warning"></b-alert>
      <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
        <b-form-group>
          <ValidationProvider rules="required|email" v-slot="{ errors }">
            <b-input v-model="formData.email" :disabled="loading" :placeholder="$t('Enter a email')"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group v-if="requireCode">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-input v-model="formData.code" :disabled="loading" :placeholder="$t('Enter a secure code')"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
      </ValidationObserver>
      <b-button variant="primary" class="text-uppercase font-weight-bold" block @click="onSubmit()">{{$t("Update a email")}}</b-button>
    </div>
    <LoadingModal :show="loading" />
  </div>  
</template>

<script>
import AccountHeader from "@/components/headers/AccountHeader";
import Template from "@/views/game/giftcode/Template";
import profileService from "@/services/profileService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "UpdateEmail",
  components: {Template, AccountHeader},
  data() {
    return {
      loading: false,
      requireCode: false,
      step: 1,
      formData: {
        email: "",
        code: ""
      }
    }
  },
  methods: {
    async verify() {
      this.loading = true;
      const response = await profileService.update();
      this.loading = false;
      if (!response) {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }
      
      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }
      
      this.requireCode = response.data.requireCode;
      this.step = 2;
    },
    async onSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid)
        return;

      this.loading = true;
      await this.$recaptchaLoaded();
      const response = await profileService.updateEmail(this.formData, await this.$recaptcha('updateEmail'))
      this.loading = false;

      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      await dialogHelper.alert(response.message, "success");
      await this.$router.push({name: "Account"})
    }
  }
}
</script>

<style scoped lang="scss">
.page-update-email {
  h1 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 3px;
  }
}
</style>